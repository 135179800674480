<template>
  <div class="container-fluid">
    <div class="card">
        <div id="report" class="card-body">
            <div class="container-fluid">
              <div style="width: 80px;">
                <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
              </div>
              <div class="headerreport">
                <div class="row">
                  {{ companydata.cmp_nmbre }}
                </div>
                <div class="row">
                  {{ fecha }}
                </div>
              </div>
            </div>
            <br>
            <div class="card-title">
              <h1 class="titlereport">Reporte de solicitudes realizadas</h1>
              <h5 class="subtitle" v-if="$route.params.query.pqrs">Sólo Tipos de Pqrs</h5>
            </div>
            <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                <table id="reportePqrs" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Clase Doc.</th>
                            <th>Año</th>
                            <th>Consecutivo</th>
                            <th>Tipo documento</th>
                            <th>Id Email</th>
                            <th>Tipo solicitud</th>
                            <th>Fecha Radicación</th>
                            <th class="entidad">Entidad</th>
                            <th class="asunto">Asunto</th>
                            <th>Ciudad</th>
                            <th>Teléfono</th>
                            <th>Correo electrónico</th>
                            <th class="dependencia">Dependencia</th>
                            <th class="serie">Serie</th>
                            <th>Tramitado por</th>
                            <th>Pendiente Respuesta</th>
                            <th>Tiempo estándar</th>
                            <th>Tiempo asignado</th>
                            <th>Tiempo utilizado</th>
                            <th>Tiempo restante</th>
                            <th>Respuesta</th>
                        </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, index) in items">
                        <tr class="rowstyle">
                          <td>{{ index + 1 }}</td>
                          <td>{{ item.tcr_cdgo }}</td>
                          <td>{{ item.dcm_ano }}</td>
                          <td>{{ item.dcm_cdgo }}</td>
                          <td>{{ item.tdo_nmbre }}</td>
                          <td>{{ item.dcm_inbox_uid }}</td>                          
                          <td>{{ item.tpq_nmbre }}</td>
                          <td>{{ item.dcm_fcharad | formatDate }}</td>
                          <td>{{ item.dcm_entidad }}</td>
                          <td>{{ item.dcm_asunto }}</td>
                          <td>{{ item.dcm_ciudad }}</td>
                          <td>{{ item.ent_telefono }}</td>
                          <td>{{ item.dcm_email }}</td>
                          <td>{{ item.dep_nmbre }}</td>
                          <td>{{ item.ser_nmbre }}</td>
                          <td>{{ item.dep_tramite_nmbre }}</td>                          
                          <td>{{ item.dcm_rspsta | formatBoolean }}</td>
                          <td>{{ item.t_estandar }}</td>
                          <td>{{ item.dcm_tres }}</td>
                          <td>{{ item.t_utilizado }}</td>
                          <td>{{ item.restante }}</td>
                          <td>{{ item.respuesta }}</td>
                        </tr>
                        <tr v-for="(item2, index2) in item.expediente_docs" class="rowstyle">
                          <td align="right">*</td>
                          <td>{{ item2.tcr_cdgo }}</td>
                          <td>{{ item2.dcm_ano }}</td>
                          <td>{{ item2.dcm_cdgo }}</td>
                          <td>{{ item2.tdo_nmbre }}</td>
                          <td>{{ item2.dcm_inbox_uid }}</td>
                          <td>{{ item2.tpq_nmbre }}</td>
                          <td>{{ item2.dcm_fcharad | formatDate }}</td>
                          <td>{{ item2.dcm_entidad }}</td>
                          <td>{{ item2.dcm_asunto }}</td>
                          <td>{{ item2.dcm_ciudad }}</td>
                          <td>{{ item2.ent_telefono }}</td>
                          <td>{{ item2.dcm_email }}</td>
                          <td>{{ item2.dep_nmbre }}</td>
                          <td>{{ item2.ser_nmbre }}</td>
                          <td>{{ item2.dep_tramite_nmbre }}</td>
                          <td>{{ item2.dcm_rspsta | formatBoolean }}</td>
                          <td>{{ item2.t_estandar }}</td>
                          <td>{{ item2.dcm_tres }}</td>
                          <td>{{ item2.t_utilizado }}</td>
                          <td>{{ item2.restante }}</td>
                          <td>{{ item2.respuesta }}</td>
                        </tr>
                      </template>
                    </tbody>
                </table>
            </div>
            <br>
            <div v-if="showProgress" style="text-align:center">
              <v-progress-circular
                :size="50"
                :width="5"
                color="blue"
                indeterminate
              ></v-progress-circular>
            </div>
        </div>
    </div>
    <br>
    <div class="row justify-content-around" align="center">
      <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
      <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
    </div>
  </div>
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import { formatDate, formatBoolean } from './../../plugins/filters';
import print from 'print-js';
import XLSX from 'xlsx';
import { tiempoUtilizado } from '../../utils';

export default {
  components: { 
    //VProgressCircular
  },
  data(){
    return{
      message: '',
      items: [],
      tpocors: [],
      tpodocs: [],
      tpopqrs: [],
      dependencias: [],
      series: [],
      debug: null,
      fecha: null,
      tcr_nmbre: '',
      showProgress: false,
      dnl: []
    }
  },
  filters: {
    formatDate, formatBoolean
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');

    this.fetchTpocor(err => {
      if (err){
        alert('Error al consultar las clases de documentos.');
      } else {
        this.fetchTpodoc(err => {
          if (err){
            alert('Error al consultar los tipos de documento.');
          } else {
            this.fetchTpopqrs(err => {
              if (err){
                alert('Error al consultar los tipos de pqrs.');
              } else {
                this.fetchDependencia(err => {
                  if (err){
                    alert('Error al consultar las dependencias.');
                  } else {
                    this.fetchSerie(err => {
                      if (err){
                        alert('Error al consultar las series.');
                      } else {
                        this.fetchDianol(err => {
                          if (err){
                            alert('Error al consultar los días no laborales.');
                          } else {
                            this.debug = this.$route.params;
                            this.tcr_nmbre = this.$route.params.tcr_nmbre;
                            this.searchItems(this.$route.params.query);
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });    
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchTpocor(callback){
      let uri = '/tpocors/correspondencia/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpocors = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchTpodoc(callback){
      let uri = '/tpodocs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpodocs = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchTpopqrs(callback){
      let uri = '/tpopqrs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpopqrs = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchDependencia(callback){
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.dependencias = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchSerie(callback){
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.series = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchDianol(callback){
      var p = {};
      p.cmp_id = this.$store.state.company;
      p.inicio = this.$route.params.query.dcm_fcharadini;
      p.fin = this.$route.params.query.dcm_fcharadfin;

      let uri = '/dianols/rango';
      this.axios.post(uri, p)
      .then((response) => {
        this.dnl = response.data;
        return callback(false);
      })
      .catch((err) => {
        return callback(true);
      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/pqrs';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;
          this.items = [];
          
          for (var i = 0; i < list.length; i++){            
            this.procesarItem(list[i], false);            
            
            if (list[i].expediente_docs != undefined){
              if (list[i].expediente_docs.length > 0){
                for (var j = 0; j < list[i].expediente_docs.length; j++){
                  this.procesarItem(list[i].expediente_docs[j], true);
                }
              }
            }            
          }

          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    procesarItem(item, relacionado){
      if (item.dcm_rspsta){
        let restante = parseInt(item.dcm_tres) - tiempoUtilizado(item.dcm_fcharad, new Date(), this.dnl);
        item.restante = restante;
      }

      for (var j = 0; j < this.tpocors.length; j++){
        if (item.tcr_id == this.tpocors[j]._id){
          item.tcr_cdgo = this.tpocors[j].tcr_cdgo;          
          j = this.tpocors.length;
        }
      }

      for (var j = 0; j < this.tpodocs.length; j++){
        if (item.tdo_id == this.tpodocs[j]._id){
          item.tdo_nmbre = this.tpodocs[j].tdo_nmbre;
          j = this.tpodocs.length;
        }
      }

      for (var j = 0; j < this.dependencias.length; j++){
        if (item.dep_id == this.dependencias[j]._id){
          item.dep_nmbre = this.dependencias[j].dep_nmbre;
          j = this.dependencias.length;
        }
      }

      var t_estandar = 0;

      for (var j = 0; j < this.series.length; j++){
        if (item.ser_id == this.series[j]._id){
          item.ser_nmbre = this.series[j].ser_nmbre;
          t_estandar = this.series[j].ser_tres;
          j = this.series.length;
        }
      }

      for (var j = 0; j < this.tpopqrs.length; j++){
        if (item.tpq_id == this.tpopqrs[j]._id){
          item.tpq_nmbre = this.tpopqrs[j].tpq_nmbre;
          if (this.tpopqrs[j].tpq_tres){
            t_estandar = this.tpopqrs[j].tpq_tres;
          }                
          j = this.tpopqrs.length;
        }

      }          
      if (!item.tpq_nmbre){
        item.tpq_nmbre = 'Otra solicitud';           
      }

      if (!relacionado){
        if (item.entidad_docs.length > 0){
          this.$set(item, 'ent_telefono', item.entidad_docs[0].ent_telefono);            
        }

        item.t_estandar = t_estandar;
        if (!item.dcm_tres){
          item.dcm_tres = t_estandar;
        }
        
        if (item.dcmnt_docs.length > 0){
          let respuesta = item.dcmnt_docs[0];
          let dependencia = this.dependencias.find(i => i._id === respuesta?.dep_tramite_id);
          item.dep_tramite_nmbre = dependencia?.dep_nmbre;

          item.t_utilizado = tiempoUtilizado(item.dcm_fcharad, respuesta.dcm_fcharad, this.dnl);

          let tpocor = this.tpocors.find(i => i._id === respuesta.tcr_id);
          item.respuesta = tpocor?.tcr_cdgo + '-' + respuesta.dcm_ano + '-' + respuesta.dcm_cdgo;
        }

        this.items.push(item);
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport(){
      var tableId = 'reporteSolicitudesRealizadas';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        report.push(this.procesarItemExport(this.items[i], i));

        if (this.items[i].expediente_docs != undefined){
          if (this.items[i].expediente_docs.length > 0){
            for (var j = 0; j < this.items[i].expediente_docs.length; j++){
              report.push(this.procesarItemExport(this.items[i].expediente_docs[j], -1));
            }
          }
        }            
      }

      var pqrs = this.$route.params.query.pqrs ? "Sólo Tipos de Pqrs" : "";

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Reporte de solicitudes realizadas"],
        [pqrs],
        [],
        ["#", "Clase documento", "Año", "Consecutivo", "Tipo documento", "Id Email", "Tipo solicitud", "Fecha Radicación", "Entidad", "Asunto", "Ciudad", "Teléfono", "Correo electrónico", "Dependencia", "Serie", "Tramitado por", "Pendiente respuesta", "Tiempo estándar", "Tiempo asignado", "Tiempo utilizado", "Tiempo restante", "Respuesta"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A8" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    },
    procesarItemExport(obj, i){
      let item = {};
      if (i > -1){
        item.index = i + 1;
      } else {
        item.index = '*';
      }
      item.tcr_cdgo = obj.tcr_cdgo;
      item.dcm_ano = obj.dcm_ano;
      item.dcm_cdgo = obj.dcm_cdgo;
      item.tdo_nmbre = obj.tdo_nmbre;
      item.dcm_inbox_uid = obj.dcm_inbox_uid;
      item.tpq_nmbre = obj.tpq_nmbre;
      item.dcm_fcharad = moment(obj.dcm_fcharad).format('DD/MMM/YYYY hh:mm A');
      item.dcm_entidad = obj.dcm_entidad;
      item.dcm_asunto = obj.dcm_asunto;
      item.dcm_ciudad = obj.dcm_ciudad;
      item.ent_telefono = obj.ent_telefono;
      item.dcm_email = obj.dcm_email;
      item.dep_nmbre = obj.dep_nmbre;
      item.ser_nmbre = obj.ser_nmbre;
      item.dep_tramite_nmbre = obj.dep_tramite_nmbre;
      item.dcm_rspsta = obj.dcm_rspsta ? 'Si' : 'No';
      item.t_estandar = obj.t_estandar;
      item.dcm_tres = obj.dcm_tres;
      item.t_utilizado = obj.t_utilizado;
      item.restante = obj.restante;
      item.respuesta = obj.respuesta;

      return item;      
    }

  } // END METHODS
}
</script>

<style>

  @import '../../../public/css/report.css';

</style>
